.contact-buttons {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;
  align-items: center;
}

.contact-button {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 9999px;
  font-size: 16px;
  color: white;
  text-decoration: none;
  text-align: center;
  width: 200px;
  transition: 0.3s ease;
  font-weight: bold;

  &.call {
    background-color: #d32f2f;

    &:hover {
      background-color: #b71c1c;
    }
  }

  &.whatsapp {
    background-color: #25d366;

    &:hover {
      background-color: #1ebe5d;
    }
  }
}

.cleaner-manager__phone {
  color: #000;
  font-size: 18px;
  font-weight: bold;
}
