.order-cleaner__page {
  max-width: 100%;
  margin: auto;
  padding: 6px;

  &__title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__loading,
  &__error,
  &__no-orders {
    text-align: center;
    font-size: 16px;
    color: #666;
  }
}

.order-cleaner__card {
  width: 100%;
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
  overflow: hidden;
  display: block !important;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid #eee;
    margin-bottom: 12px;
  }

  &__name {
    font-size: 18px;
    font-weight: bold;
    color: #222;
  }
}

.order-cleaner__list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}

.order-cleaner__item {
  background: #f9f9f9;
  padding: 12px;
  border-radius: 8px;
  width: calc(50% - 10px);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  transition: 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }

  &__number {
    font-weight: bold;
    font-size: 14px;
    color: #444;
  }

  &__type,
  &__address,
  &__price {
    font-size: 13px;
    color: #555;
  }

  &__status {
    font-size: 13px;
    font-weight: bold;

    &--paid {
      color: #2d8f4e;
    }
    &--unpaid {
      color: #c0392b;
    }
  }
}
