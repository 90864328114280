.cleaner-profile {
  background: #e7e4e4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  font-family: sans-serif;

  &__header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__icon {
    width: 60px;
    height: 60px;
    fill: red;
    margin-bottom: 10px;
  }

  &__name {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
  }

  &__id {
    font-size: 14px;
    color: #555;
    margin: 5px 0 20px;
  }

  &__income {
    margin: 0;
    font-size: 36px;
    font-weight: 500;
    color: #fff;
    background: none;
  }

  &__desc {
    color: #444;
    margin-top: 5px;
  }

  &__payment {
    text-align: center;
    width: 100%;
    max-width: 320px;
  }

  &__requisites {
    margin-bottom: 20px;
    font-size: 14px;
    color: #333;
  }

  &__card {
    background: #e60000;
    color: #fff;
    border-radius: 16px;
    padding: 20px;
    position: relative;
    margin-bottom: 82px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

    .card__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card__bank {
      font-size: 28px;
      font-weight: bold;
    }

    .card__mir {
      height: 24px;
    }

    .card__number {
      margin: 20px 0;
      font-size: 20px;
      letter-spacing: 2px;
    }

    .card__bottom {
      display: flex;
      justify-content: space-between;
      font-size: 12px;

      .label {
        display: block;
        opacity: 0.8;
        margin-bottom: 2px;
      }

      .value {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }

  &__btn {
    background: red;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    margin-top: 12px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    cursor: pointer;
    opacity: 1;
    transition: 0.3s ease;
  }

  &__btn:hover {
    opacity: 0.9;
  }
}
