.cleaner-orders {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 6px;
  max-width: 100%;
}

.order-list__back {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  width: 95%;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  color: #000;
  margin: 28px auto;
  box-sizing: border-box;
}

.cleaner-order {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 14px;
  max-width: 100%;
  width: 100%;
  box-sizing: border-box;
}

.cleaner-order__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.cleaner-order__number {
  font-size: 22px;
  font-weight: bold;
}

.cleaner-order__status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 110px;
}

.cleaner-order__status {
  padding: 4px 8px;
  border-radius: 6px;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  white-space: nowrap;
}

.cleaner-order__status.pending {
  background: #ffeb3b;
  color: #333;
}

.cleaner-order__status.paid {
  background: #4caf50;
  color: #fff;
}

.cleaner-order__status.canceled {
  background: #f44336;
  color: #fff;
}

.cleaner-order__date {
  font-size: 12px;
  color: #666;
  margin-top: 4px;
  text-align: right;
  white-space: nowrap;
}

.cleaner-order__address {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  line-height: 1.4;
}

.cleaner-order__details {
  display: flex;
  flex-direction: column;
  gap: 18px;
  font-size: 15px;
  flex-wrap: wrap;
}

.cleaner-order__services {
  background: #f9f9f9;
  padding: 8px;
  border-radius: 8px;
}

.cleaner-order__services h4 {
  margin: 0 0 6px;
  font-size: 14px;
}

.cleaner-order__services ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.cleaner-order__services li {
  font-size: 13px;
  line-height: 1.4;
}

.cleaner-orders__empty {
  text-align: center;
  font-size: 14px;
  color: #666;
}

@media (max-width: 400px) {
  .cleaner-order {
    padding: 12px;
  }

  .cleaner-order__header {
    flex-direction: column;
    gap: 6px;
  }

  .cleaner-order__status-container {
    align-items: flex-start;
  }

  .cleaner-order__status {
    font-size: 11px;
  }

  .cleaner-order__date {
    font-size: 14px;
    text-align: left;
  }

  .cleaner-order__number {
    font-size: 22px;
  }

  .cleaner-order__details {
    font-size: 18px;
  }

  .cleaner-order__services h4 {
    font-size: 13px;
  }

  .cleaner-order__services li {
    font-size: 12px;
  }
}
