.users-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: #ffe4e4;
  padding: 20px;
  margin: 22px 0;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  &__info {
    flex: 1;
    margin-right: 20px;

    &-name {
      font-size: 24px;
      color: #333;
      margin-bottom: 10px;
    }

    &-phone,
    &-email {
      font-size: 16px;
      color: #555;
    }
  }

  &__address {
    flex: 1;
    margin-right: 20px;

    &-title {
      font-size: 18px;
      font-weight: bold;
      color: #333;
      margin-bottom: 10px;
    }

    p {
      font-size: 14px;
      color: #555;
      margin: 5px 0;
    }
  }

  &__select {
    width: 100%;
    max-width: 200px;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ccc;
    background-color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: #888;
    }

    &:focus {
      outline: none;
      border-color: #4caf50;
      box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-balance {
      margin-bottom: 20px;
      text-align: center;

      &-title {
        font-size: 18px;
        color: #333;
      }

      &-value {
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }
    }

    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .users-card__button {
        padding: 10px 3px;
        font-size: 12px;
        border: none;
        border-radius: 8px;
        width: 136px;
        cursor: pointer;
        margin-bottom: 10px;
        transition: background-color 0.3s ease;

        &--add {
          background-color: #fff;
          color: #333;
          border: 1px solid #ddd;

          &:hover {
            background-color: #f0f0f0;
          }
        }

        &--delete {
          background-color: #ff4d4f;
          color: #fff;

          &:hover {
            background-color: #e63946;
          }
        }
      }
    }
  }
}

.users-card__buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.users-card__button {
  padding: 10px 2px;
  width: 142px;
  font-size: 12px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin: 6px 0;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  border: 1px solid #ddd;
  background: #ffffff;
  &--delete {
    background-color: #ff4d4f;
    color: #fff;

    &:hover {
      background-color: #e63946;
    }
  }
}
.users-card__balance-value {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  margin: 6px 0;
  font-size: 24px;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  border: 1px solid #ddd;
  background: #ffffff;
}
