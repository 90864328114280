.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffd8d8;
  // padding: 8px;
  font-family: Arial, sans-serif;
  width: 100%;
  box-sizing: border-box;
  height: 100vh;
  justify-content: space-between;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #008cba;
    color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
    gap: 10px;
    box-sizing: border-box;

    @media (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }

    &-top {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;

      .logout-btn {
        background-color: #ff4d4f;
        color: white;
        border: none;
        padding: 8px 12px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #e63946;
        }
      }
    }

    &-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
      font-size: 14px;

      .icon {
        font-size: 20px;
      }

      .count,
      .amount {
        font-size: 18px;
        font-weight: bold;
      }
    }
  }

  &__notification {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    text-decoration: none;
    color: #fff;
  }

  &__earnings {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    text-decoration: none;
    color: #fff;
  }

  &__profile {
    background-color: #fff;
    color: #000;
    padding: 12px 15px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-bottom: 35px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    min-width: 140px;
    min-height: 50px;
    font-size: 14px;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 20px;
  }

  &__button {
    background-color: #fff;
    color: #000;
    padding: 22px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}

.cleaner-menu {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &__item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px;
    background-color: #ffa0a0;
    text-align: center;
    font-size: 14px;
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

  &__icon {
    font-size: 35px;
    margin-bottom: 5px;

    &.red {
      color: red;
    }

    &.green {
      color: lime;
    }

    &.black {
      color: black;
    }
  }
}
