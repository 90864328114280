// .authorization {
//   width: 462px;
//   margin: 92px auto;
//   padding: 22px 12px;
//   box-sizing: border-box;
//   background: #FFFFFF;
//   border-radius: 10px;
//   box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);

//   .authorization__header {
//     text-align: center;
//     font-size: 24px;
//     font-weight: 600;
//     margin-bottom: 22px;
//   }

//   .authorization__employees {
//     display: flex;
//     width: 80%;
//     justify-content: space-between;
//     margin: 0 auto;
//   }

//   .authorization__employee {
//     display: flex;
//     width: 45%;
//     margin: 8px 0;
//     justify-content: center;
//     padding: 12px 18px;
//     cursor: pointer;
//     box-sizing: border-box;
//     background: #FFFFFF;
//     border-radius: 12px;
//     box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
//     transition: 0.3s;

//     a {
//       text-decoration: none;
//       color: #000000;
//     }

//     &:hover {
//       box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.225);
//     }
//   }

//   .authorization__employee-active {
//     background: #2696D6;
//     color: #FFFFFF;
//   }

//   .authorization__button {
//     width: 80%;
//     padding: 8px;
//     text-align: center;
//     color: #FFFFFF;
//     font-size: 22px;
//     line-height: 100%;
//     cursor: pointer;
//     margin: 0 auto 9px auto;
//     display: block;
//     box-sizing: border-box;
//     background: #2696D6;
//     box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
//     border-radius: 15px;
//     transition: 0.3s;
//     border: none;

//     &:hover {
//       box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.261);
//     }
//   }

//   label {
//     margin: 18px auto;
//     display: block;
//     width: 80%;

//     input {
//       width: 100%;
//       display: block;
//       margin: 4px auto;
//       box-sizing: border-box;
//       padding: 8px 18px;
//       background: #FFFFFF;
//       border: 1px solid #DFDFDF;
//       border-radius: 15px;
//       color: rgba(0, 0, 0, 0.56);
//     }
//   }
// }

// .nav__sign-modal-input-yandex {
//   color: white;
//   background-color: black;
//   font-size: 20px;
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   padding: 12px;
//   border-radius: 12px;
// }

// @media(max-width: 462px) {
//   .authorization {
//     width: 95%;
//   }

//   .authorization__employees {
//     width: 100% !important;
//   }

//   .authorization__employee {
//     width: 48% !important;
//   }
// }

.authorization {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;

  &__container {
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;

    h2 {
      margin-bottom: 20px;
      font-size: 22px;
      font-weight: bold;
    }

    form {
      display: flex;
      flex-direction: column;

      .input-group {
        margin-bottom: 15px;
        text-align: left;

        label {
          display: block;
          font-size: 14px;
          margin-bottom: 5px;
          font-weight: 500;
        }

        input {
          width: 100%;
          padding: 10px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 14px;
          box-sizing: border-box;
        }
      }

      button {
        background-color: #007bff;
        color: white;
        font-size: 16px;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background-color: #0056b3;
        }
      }
    }

    p {
      font-size: 14px;
      margin-top: 10px;
    }

    .toggle-auth {
      color: #007bff;
      cursor: pointer;
      font-weight: bold;
      &:hover {
        text-decoration: underline;
      }
    }

    .yandex-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 15px;
      padding: 10px;
      width: 100%;
      border: none;
      background: white;
      border: 1px solid #ccc;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s;

      img {
        margin-right: 10px;
      }

      &:hover {
        background: #f0f0f0;
      }
    }
  }
}
