$primary-color: #007bff;
$hover-color: darken($primary-color, 10%);
$background-color: #f4f4f4;
$box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $background-color;

  .register-box {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: $box-shadow;
    width: 350px;
    text-align: center;

    h2 {
      margin-bottom: 15px;
      color: $primary-color;
    }

    form {
      display: flex;
      flex-direction: column;

      .input-group {
        margin-bottom: 15px;
        text-align: left;

        label {
          font-weight: bold;
          display: block;
          margin-bottom: 5px;
        }

        input {
          width: 100%;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 5px;
          font-size: 16px;
          box-sizing: border-box;
        }
      }

      button {
        background: $primary-color;
        color: white;
        padding: 10px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;
        transition: background 0.3s ease-in-out;

        &:hover {
          background: $hover-color;
        }
      }
    }

    .message {
      margin-top: 15px;
      color: red;
      font-weight: bold;
    }
  }
}
