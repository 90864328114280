.login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/bg-login.png");
  background-size: cover;
}

.login-subtitle {
  margin-bottom: 20px;
  color: #222;
  font-size: 16px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.login-input {
  padding: 10px;
  border-radius: 9999px;
  border: none;
  background: #111;
  color: #fff;
  text-align: center;
  font-size: 16px;
  box-shadow: 2px 2px 4px #00000040;
}

.login-button {
  padding: 10px;
  border-radius: 9999px;
  background: #d32f2f;
  color: #fff;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.login-button:hover {
  background: #b71c1c;
}
